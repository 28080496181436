<template>
    <div class="app-container">
        <div class="filter-container">
            <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>
            <el-input class="filter-item" v-model="listQuery.name" placeholder="名称" style="width: 220px;" clearable/>
            <el-select class="filter-item" v-model="listQuery.role_type" placeholder="角色类型" style="width: 140px" clearable>
                <el-option label="分拣员" :value="1"></el-option>
                <el-option label="配送员" :value="2"></el-option>
            </el-select>
            <el-select class="filter-item" v-model="listQuery.service_type" placeholder="服务类型" style="width: 140px" clearable>
                <el-option label="投餐服务" :value="1"></el-option>
                <el-option label="上楼服务" :value="2"></el-option>
            </el-select>
            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    <!-- {{ scope.$index }} -->
                    {{ scope.row.id }}
                </template>
            </el-table-column>
            <el-table-column label="名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>

            <el-table-column label="角色类型" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.role_type === 1" type="warning">分拣员</el-tag>
                    <el-tag v-else-if="scope.row.role_type === 2" type="success">配送员</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="服务类型" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.service_type === 1" type="primary">投餐服务</el-tag>
                    <el-tag v-else-if="scope.row.service_type === 2" type="danger">上楼服务</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="上班时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.clock_in }}
                </template>
            </el-table-column>

            <el-table-column label="下班时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.clock_out }}
                </template>
            </el-table-column>

            <el-table-column label="码的有效期(分钟)" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.code_effective_time }}
                </template>
            </el-table-column>

            <el-table-column label="码的到期时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.code_expiration_time }}
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button type="success" size="mini" @click="handleClockIn(scope.row, 1)" style="margin: 3px;">上班码</el-button>
                    <el-button type="warning" size="mini" @click="handleClockIn(scope.row, 2)" style="margin: 3px;">下班码</el-button>
                    <el-button type="primary" size="mini" @click="handleRefresh(scope.row)" style="margin: 3px;">刷新码</el-button>
                    <el-button type="danger" size="mini" @click="handleDel(scope.row.id)" style="margin: 3px;">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="150px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="角色类型" prop="role_type">
                    <el-select v-model="form.role_type">
                        <el-option label="分拣员" :value="1"/>
                        <el-option label="配送员" :value="2"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="服务类型" prop="service_type">
                    <el-select v-model="form.service_type">
                        <el-option label="投餐服务" :value="1"/>
                        <el-option label="上楼服务" :value="2"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="上班时间" prop="clock_in">
                    <el-time-picker v-model="form.clock_in" format="HH:mm" value-format="HH:mm" placeholder="选择时间"></el-time-picker>
                </el-form-item>

                <el-form-item label="下班时间" prop="clock_out">
                    <el-time-picker v-model="form.clock_out" format="HH:mm" value-format="HH:mm" placeholder="选择时间"></el-time-picker>
                </el-form-item>

                <el-form-item label="码的有效期(分钟)" prop="code_effective_time">
                    <el-input-number v-model="form.code_effective_time" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->

        <!-- 打卡码 -->
        <el-dialog :title="clockInTitle" :visible.sync="dialogClockInVisible" width="440px" :close-on-click-modal="false">
            <img :src="base64" style="width: 400px; height: 400px;">
        </el-dialog>
        <!-- 打卡码 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";
    var moment = require("moment");

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    name: '',
                    role_type: '',
                    service_type: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '修改',
                    create: '新增',
                },
                btnLoading: false,
                form:{
                    id: '',
                    school_id: '',
                    name: '',
                    role_type: '',
                    service_type: '',
                    clock_in: '',
                    clock_out: '',
                    code_effective_time: '',
                },
                rules: {
                    name: [{ required: true, message: '请输入名称', trigger: 'change' }],
                    role_type: [{ required: true, message: '请选择角色类型', trigger: 'change' }],
                    service_type: [{ required: true, message: '请选择服务类型', trigger: 'change' }],
                    clock_in: [{ required: true, message: '请选择上班时间', trigger: 'change' }],
                    clock_out: [{ required: true, message: '请选择下班时间', trigger: 'change' }],
                    code_effective_time: [{ required: true, message: '请输入码的有效期', trigger: 'change' }],
                },
                // 打卡码
                clockInTitle: '',
                dialogClockInVisible: false,
                base64: '',
            };
        },
        created() {
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/attendanceCode/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;

                    // 构造码的到期时间
                    this.list.forEach(item => {
                        item.code_expiration_time = moment(item.effective_code.created_at).add(item.code_effective_time, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    })
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    id: '',
                    school_id: '',
                    name: '',
                    role_type: '',
                    service_type: '',
                    clock_in: '',
                    clock_out: '',
                    code_effective_time: '',
                }
                this.btnLoading = false
            },
            handleCreate() {
                this.resetForm()
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            handleUpdate(row) {
                this.resetForm()
                this.form = Object.assign({}, row)
                this.dialogStatus = 'update'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData() {
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.form.school_id = this.school_id;
                }
                if (this.form.clock_in > this.form.clock_out) {
                    this.$message({
                        type: "warning",
                        message: "下班时间不能低于上班时间"
                    });
                    return;
                }
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/attendanceCode/save',
                            method: 'post',
                            data: this.form
                        }).then(response => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                        }).catch((err) => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            handleDel(val) {
                this.$confirm('确认继续该操作', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: '/api/backend/attendanceCode/delete',
                        method: 'post',
                        data: {id: val}
                    }).then(()=>{
                        this.getList()
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                    })
                }).catch(() => {

                });
            },
            // 打卡码 status：1.打卡上班 2.打卡下班
            handleClockIn(row, status){
                if (status === 1) {
                    this.clockInTitle = '上班码'
                } else if (status === 2) {
                    this.clockInTitle = '下班码'
                }
                this.base64 = ''
                request({
                    url: "/api/common/qrcode/base64",
                    method: "post",
                    data: {
                        url: JSON.stringify({
                            code_no: row.code_no,
                            status: status,
                        })
                    }
                }).then(response => {
                    this.base64 = response.data;
                }).finally(()=>{
                    this.dialogClockInVisible = true
                });
            },
            // 刷新码
            handleRefresh(row){
                request({
                    url: '/api/backend/attendanceCode/refresh',
                    method: 'post',
                    data: {id: row.id}
                }).then(response => {
                    this.$message({
                        type: 'success',
                        message: '操作成功'
                    });
                    this.getList();
                }).catch(() => {

                });
            },
        }
    };
</script>


<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
